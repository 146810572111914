import React, { useState } from "react";
import { CgSortAz } from "react-icons/cg";
import { BiSearch } from "react-icons/bi";
import house1 from "../../assets/house1.png";
import { HiLocationMarker } from "react-icons/hi";
import { BsBookmarkDash } from "react-icons/bs";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { Alert, Button } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
import { Fragment } from "react";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const Products = () => {
  const [page, setpage] = useState(1);
  const navigate = useNavigate();
  const uri = useSelector((state) => state.ApiReducer.apartment_uri);
  const [apartmentTray, setApartmentTray] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
  ]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tray, setTray] = useState([]);
  const [currentInd, setCurrentInd] = useState(0);

  // const front = (pag) => {
  //   if (page === 3) {
  //     setpage(3);
  //   } else {
  //     setpage(page + 1);
  //   }
  // };

  // const back = (pag) => {
  //   if (page === 1) {
  //     setpage(1);
  //   } else {
  //     setpage(page - 1);
  //   }
  // };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    axios
      .get(`${uri}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 200) {
          setIsLoading(false);
          let response = res.data.data.filter(each=>each.images.length !== 0)
          setApartmentTray(response);
          distribute(response);
        } else setError("Something went wrong");
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Internal Server Error");
      });
  }, []);

  const distribute = (array) => {
    setTray(array.filter((each, i) => i + 1 <= 6));
    setCurrentInd(6);
  };

  const next = () => {
    setTray(
      apartmentTray.filter(
        (each, i) => i + 1 > currentInd && i + 1 <= currentInd * 2
      )
    );
    setCurrentInd(currentInd * 2);
  };

  const prev = () => {
    setTray(
      apartmentTray.filter(
        (each, i) => i + 1 < currentInd && i + 1 <= currentInd / 2
      )
    );
    setCurrentInd(currentInd / 2);
  };

  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="flex flex-col items-center justify-center gap-[4rem] mb-5">
        <div
          className="flex items-center justify-center md:gap-4 "
          data-aos="slide-up"
        >
          <div className="flex items-center">
            <input
              className=" focus:outline-colorb font-lato not-italic font-[500] text-[16px] text-colora flex items-center justify-between border-2 rounded-[12px] bg-[#F7F7F7] h-[55px] md:w-[700px] md:h-[70px] px-[3rem]"
              placeholder="Search"
            />
            <BiSearch size={20} className=" absolute ml-[1.5rem] text-colora" />
            <CgSortAz
              size={20}
              className=" absolute md:ml-[40em] ml-[20em] cursor-pointer"
            />
          </div>
          <button className="border-2 border-transparent font-lato not-italic font-semibold text-[14px] md:text-[16px] text-[#ffffff] h-[40px] w-[30%] md:w-[150px] md:h-[70px] bg-colorb rounded-[8px] hidden md:flex items-center justify-center hover:bg-transparent duration-500 cursor-pointer gap-2 hover:border-colorb hover:text-colorb">
            <BiSearch size={25} />
            Search
          </button>
        </div>
        {isLoading ? (
          <span className="spinner-border text-webhostels"></span>
        ) : !isLoading && error != "" ? (
          <Alert severity="error" className="col-md-6">
            An error occured
          </Alert>
        ) : error == "" && apartmentTray.length == 0 ? (
          <p className="display-5 text-center animate__animated animate__jello animate__slower animate__infinite">
            No available apartment
          </p>
        ) : (
          <div className="container">
            <div className="row w-100 px-md-5 mx-auto">
              {tray.map((each, i) => (
                <div
                  key={i}
                  className="col-md-6 px-lg-4 mb-4"
                  data-aos="zoom-in"
                >
                  <div className="border-colorb border-[1px] py-[2rem] px-[2rem] rounded-lg transition-ease duration-500">
                    <div className="d-flex flex-lg-row flex-column gap-[2rem] ">
                      <img
                        src={each.images[0]}
                        alt="property"
                        className="rounded-lg img-fluid col-md-6"
                      />
                      <div className="flex flex-col md:gap-3 pt-1 ">
                        <div className="d-flex justify-content-between font-lato not-italic font-semibold text-[16px] text-colorb">
                          Self contain
                          <div className="ms-5 h-[32px] w-[32px] bg-[#D8D9F0] flex items-center justify-center ">
                            <BsBookmarkDash
                              size={20}
                              className="text-colorb cursor-pointer transition-ease duration-500"
                            />
                          </div>
                        </div>
                        <div className="font-lato not-italic font-semibold text-[20px] text-colora">
                          <NumericFormat value={each.yearlyRates} prefix="₦" displayType="text" thousandSeparator={true} />
                        </div>
                        <div className="flex items-center  gap-3">
                          <div>
                            <HiLocationMarker
                              className="text-colorb"
                              size={20}
                            />
                          </div>
                          <div className="font-lato font-semibold text-[14]">
                            {each.address.streetAddress}
                          </div>
                        </div>
                        <button
                          onClick={() => navigate(`/apartment/${each.id}`)}
                          className="mt-2 mt-md-4 cursor-pointer md:mt-[2rem] py-[1rem] px-[1rem] bg-[#fff] border-2 border-colorb font-lato font-semibold text-[12px] text-colorb rounded-md transition-ease duration-500 hover:bg-colorb hover:text-[#fff]"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Controller */}
            <div className="d-flex justify-content-between">
              <Button
                disabled={currentInd <= 6}
                onClick={prev}
                className="fw-bold text-primary"
              >
                Prev
              </Button>
              <Button
                className="fw-bold text-primary"
                disabled={currentInd >= apartmentTray.length}
                onClick={next}
              >
                Next
              </Button>
            </div>
            {/* Controller */}
          </div>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Products;
