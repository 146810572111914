import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const basicSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email")
    .required("Field Required"),
  firstname: yup.string().required("Field Required"),
  lastname: yup.string().required("Field Required"),
  phonenumber: yup
    .number("Invalid Number")
    .positive("Invalid Number")
    .integer("Invalid Number")
    .typeError("Invalid Number")
    .required("Field Required"),
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, {
      message: "Password must have a number and upper and lowercase letters",
    })
    .required("Field Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match!")
    .required("Field Required"),
});
export const signupSchema = yup.object().shape({
  full_name: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  mobile_number: yup
    .string()
    .required("Required")
    .min(10, "Phone Number must be at least 11 charsets")
    .max(14),
  password: yup
    .string()
    .required("Required")
    .matches(passwordRules, "Please create a stronger password"),
});
export const loginSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export const merchantSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  mobile_number: yup
    .string()
    .required("Required")
    .min(10, "Phone Number must be at least 11 charsets")
    .max(14),
  avatar: yup.object().shape({
    base64: yup.string().required("Required"),
    mimetype: yup.string().required("Required"),
  }),
  street_address: yup.string().required("Required"),
  street_no: yup.string().required("Required"),
  password: yup
    .string()
    .required("Required")
    .matches(passwordRules, "Please create a stronger password"),
});

export const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid Email Address").required("Required"),
  phoneNumber: yup.string().required("Required"),
  message: yup.string().required("Required"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("Invalid Email Address").required("Required"),
});

export const resetpasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
