const initState = {
  details: "Logged out",
};

const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case "setUser":
      return { ...state, details: action.payload };

    default:
      return state;
  }
};

export default UserReducer;
