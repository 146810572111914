import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import hostelogo from "../../assets/new1.png";
import hostelogo2 from "../../assets/new2.png";
import { MdClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const user = useSelector((state) => state.UserReducer.details);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState("#");
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const navbarToggler = () => {
    setToggleNavbar(!toggleNavbar);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    JSON.parse(localStorage.getItem("token")) !== null
      ? dispatch({ type: "setUser", payload: "Logged in" })
      : dispatch({ type: "setUser", payload: "Logged out" });
  }, []);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch({ type: "setUser", payload: "Logged out" });
    navigate("/");
  };

  return (
    <div>
      <div className="flex justify-center lg:justify-around items-center bg-backgrounda py-[2rem]">
        <div className="scale-100 flex lg:100 flex-col items-center justify-center">
          <div className="flex gap-1" data-aos="zoom-in">
            <img src={hostelogo} className=" w-[29px] h-[54px]" alt="logo" />
            <img src={hostelogo2} className=" w-[29px] h-[54px]" alt="logo" />
          </div>
          <div
            className="font-inter not-italic font-bold text-colorb text-[18px]"
            data-aos="zoom-out"
          >
            Webhostelsng
          </div>
        </div>

        <nav>
          <ul className="hidden lg:flex gap-11">
            <Link to="/">
              <li
                data-aos="flip-down"
                onClick={() => setActive("#")}
                className={
                  active === "#"
                    ? "cursor-pointer not-italic text-[20px] font-bold text-colorb font-lato"
                    : "not-italic font-lato  font-bold text-[20px] text-colora cursor-pointer"
                }
              >
                Home
              </li>
            </Link>
            <Link to="/products">
              <li
                data-aos="flip-down"
                onClick={() => setActive("#rent")}
                className={
                  active === "#rent"
                    ? "cursor-pointer not-italic font-lato text-[20px] font-bold text-colorb "
                    : "not-italic font-lato font-bold text-[20px] text-colora cursor-pointer"
                }
              >
                Rent an Apartment
              </li>
            </Link>
            <Link to="/about">
              <li
                data-aos="flip-down"
                onClick={() => setActive("#about")}
                className={
                  active === "#about"
                    ? "cursor-pointer not-italic text-[20px] font-bold text-colorb font-lato"
                    : "not-italic font-lato  font-bold text-[20px] text-colora cursor-pointer"
                }
              >
                About
              </li>
            </Link>
            <Link to="/contact">
              <li
                data-aos="flip-down"
                onClick={() => setActive("#contact")}
                className={
                  active === "#contact"
                    ? "cursor-pointer not-italic text-[20px] font-bold text-colorb font-lato"
                    : "not-italic font-lato  font-bold text-[20px] text-colora cursor-pointer"
                }
              >
                Contact
              </li>
            </Link>
          </ul>
        </nav>
        <div className=" hidden lg:flex gap-4" data-aos="slide-left">
          {user !== "Logged out" ? (
            <div className="d-flex">
              <button
                onClick={logout}
                className="cursor-pointer bg-backgrounda justify-center items-center font-lato rounded-md px-6 py-3 text-colora border-2 border-colorb
              hover:bg-colorb hover:text-backgrounda transition-ease duration-500"
              >
                Logout
              </button>
            </div>
          ) : (
            <>
              <Link to="/login">
                <button
                  className="cursor-pointer bg-backgrounda justify-center items-center font-lato rounded-md px-6 py-3 text-colora border-2 border-colorb
                hover:bg-colorb hover:text-backgrounda transition-ease duration-500"
                >
                  Login
                </button>
              </Link>
              <Link to="/signupas">
                <button
                  className="cursor-pointer justify-center items-center rounded-md px-6 py-3 font-lato border-2 border-colorb bg-colorb text-backgrounda
                transition-ease duration-500 hover:text-colora hover:bg-backgrounda hover:border-colorb"
                >
                  Sign-up
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="flex  lg:hidden bg-[rgba(204,204,204,0.12)] justify-end px-2 cursor-pointer py-0 ">
          {!toggleNavbar ? (
            <GiHamburgerMenu
              style={{ fontSize: "30px" }}
              onClick={navbarToggler}
            />
          ) : (
            <MdClose style={{ fontSize: "30px" }} onClick={navbarToggler} />
          )}
        </div>
        <ul
          className={
            toggleNavbar
              ? "flex-col lg:hidden py-0  bg-backgrounda  absolute z-20 h-screen w-4/5 transition-ease duration-700 left-[0%]"
              : " absolute left-[-100%] duration-700 transition-ease flex-col lg:hidden py-0 bg-backgrounda   z-10 h-screen w-4/5"
          }
        >
          <Link to="/">
            <li
              onClick={() => setActive("#")}
              className={
                active === "#"
                  ? " font-lato cursor-pointer not-italic text-md font-bold text-colorb py-4 px-6"
                  : "py-4 px-6 not-italic font-lato font-bold text-md text-text1 cursor-pointer"
              }
            >
              Home
            </li>
          </Link>
          <Link to="/products">
            <li
              onClick={() => setActive("#rent")}
              className={
                active === "#rent"
                  ? "font-lato py-4 px-6 cursor-pointer not-italic text-md font-bold text-colorb "
                  : "py-4 px-6 not-italic  font-lato font-bold text-md text-text1 cursor-pointer"
              }
            >
              Rent an Apartment
            </li>
          </Link>
          <Link to="/about">
            {" "}
            <li
              onClick={() => setActive("#about")}
              className={
                active === "#about"
                  ? "py-4 px-6 font-lato cursor-pointer not-italic text-md font-bold text-colorb "
                  : "py-4 px-6 not-italic  font-bold text-md text-text1 font-lato cursor-pointer"
              }
            >
              About
            </li>
          </Link>
          <Link to="/contact">
            {" "}
            <li
              onClick={() => setActive("#contact")}
              className={
                active === "#contact"
                  ? "py-4 px-6 font-lato cursor-pointer not-italic text-md font-bold text-colorb "
                  : "py-4 px-6 not-italic font-lato  font-bold text-md text-text1 cursor-pointer"
              }
            >
              Contact
            </li>
          </Link>
          <div className="flex mt-[1rem] gap-4 px-4">
            {user !== "Logged out" ? (
              <button
                onClick={logout}
                className="text-sm cursor-pointer bg-backgrounda justify-center items-center rounded-md px-3 py-1.5 text-colora border-2 border-colorb
          hover:bg-colorb hover:text-backgrounda transition-ease duration-500"
              >
                Logout
              </button>
            ) : (
              <>
                <Link to="/login">
                  <button
                    className=" text-sm cursor-pointer bg-backgrounda justify-center items-center rounded-md px-3 py-1.5 text-colora border-2 border-colorb
                hover:bg-colorb hover:text-backgrounda transition-ease duration-500"
                  >
                    Login
                  </button>
                </Link>
                <Link to="/signupas">
                  <button
                    className=" cursor-pointer text-sm justify-center items-center rounded-md px-3 py-1.5 border-2 border-colorb bg-colorb text-backgrounda
                transition-ease duration-500 hover:text-colora hover:bg-backgrounda hover:border-colorb"
                  >
                    Sign-up
                  </button>
                </Link>
              </>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
