import { Routes, Route, BrowserRouter } from "react-router-dom";
import Scrollup from "./components/scrolltotop/Scrollup";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import { RoutesObjects } from "./routes";
import Verify from "./pages/verify/Verify";
import ForgotPassword from "./components/password-reset/ForgotPassword";
import CheckMail from "./components/password-reset/CheckMail";
import ResetSuccessful from "./components/password-reset/ResetSuccessful";
import ResetPassword from "./components/password-reset/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {RoutesObjects.map((route) => {
            return (
              <Route key={route.id} path={route.path} element={route.element} />
            );
          })}
          <Route path="/verify" element={<Verify />}>
            <Route
              path="/verify/forgot-password"
              element={<ForgotPassword />}
            />
            <Route path="/verify/checkMail" element={<CheckMail />} />
            <Route path="/verify/success" element={<ResetSuccessful />} />
            <Route
              path="/verify/reset-password/:id/:token"
              element={<ResetPassword />}
            />
          </Route>
        </Routes>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  );
}
export default App;
