import React, { Fragment } from "react";
import Logo from "../../assets/logo-white.png";
import { Button } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const Verify = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="container-fluid bg-webhostels py-2">
        <div className="d-flex justify-content-between">
          <div>
            <img src={Logo} className="img-fluid" />
          </div>
          <div className="d-md-block d-none">
            <div className="d-flex py-2">
              <div>
                <Button
                  onClick={() => navigate("/login")}
                  className="bg-webhostels border border-white text-white px-5 me-md-2 py-3"
                >
                  Login
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => navigate("/signupas")}
                  className="bg-white text-webhostels px-5 me-md-2 py-3"
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Verify;
