const initState = {
  account_uri: `${process.env.REACT_APP_BE_BASE_URL}/account/`,
  apartment_uri: `${process.env.REACT_APP_BE_BASE_URL}/apartment/`,
  base_uri: `${process.env.REACT_APP_BE_BASE_URL}/`,
};

const ApiReducer = (state = initState, action) => {
  return state;
};

export default ApiReducer;
