import React, { useState } from "react";
import Picture from "../../assets/group.png";
import { Alert, Button, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import { contactSchema } from "../../validationschema";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { Fragment } from "react";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import Footer from "../footer/Footer";

const ContactUs = () => {
  const api = useSelector((state) => state.ApiReducer.base_uri);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const {
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      axios
        .post(`${api}user-feedback`, values)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === 200) {
            setMessage(res.data.message);
            setIsOpen(true);
            handleReset();
          } else {
            // setMessage(res.data)
            console.log(res.data);
          }
        })
        .catch((err) => {
          err.response
            ? setMessage(err.response.data.message)
            : setMessage("An error occured!");
          setIsOpen(true);
          setIsLoading(false);
          console.log(err);
        });
    },
  });
  const handleClose = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="row w-100 mx-auto container mb-5" data-aos="fade-in">
        <div className="col-md-6">
          <img src={Picture} className="img-fluid" />
        </div>
        <div className="col-md-6">
          <form>
            <p className="fs-2 fw-bold pt-md-5">Send us a message</p>
            <p className="py-3">
              Do you have a question? Inquiry? Request ? Please fill this form
            </p>
            <div className="form-group my-4">
              <label className="form-label">Email Address</label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                className="form-control form-control-lg"
                placeholder="youremail@address.com"
                value={values.email}
              />
              {errors.email && touched.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
            <div className="form-group my-4">
              <label className="form-label">Phone Number</label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                name="phoneNumber"
                className="form-control form-control-lg"
                placeholder="+234 816 457 2165"
                value={values.phoneNumber}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <div className="text-danger">{errors.phoneNumber}</div>
              )}
            </div>
            <div className="form-group my-4">
              <label className="form-label">Your Message</label>
              <textarea
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                rows={"5"}
                className="form-control form-control-lg"
                placeholder="Type in your message"
                value={values.message}
              />
              {errors.message && touched.message && (
                <div className="text-danger">{errors.message}</div>
              )}
            </div>
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              className={
                isLoading
                  ? "px-4 py-3 my-4 bg-light border border-primary text-primary fw-bolder"
                  : "btn-webhostels px-4 py-3 my-4"
              }
            >
              {isLoading ? "Submitting..." : "Submit Message"}
            </Button>
          </form>
        </div>

        {/* Snackbar */}
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={
              message.toLowerCase().includes("successfully")
                ? "success"
                : "error"
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
