import { Alert, Button, Snackbar } from "@mui/material";
import Aos from "aos";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "aos/dist/aos.css";
import { Fragment } from "react";
import Navbar from "../../components/navbar/Navbar";
import Scrollup from "../../components/scrolltotop/Scrollup";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useSelector((state) => state.ApiReducer.account_uri);
  const params = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const verifyEmail = async () => {
    if (params.userType == "client") {
      setIsLoading(true);
      const payload = { ...params, userType: "CUSTOMER_USER" };
      await axios({
        method: "post",
        url: `${api}${payload.id}/verify`,
        data: payload,
      })
        .then((res) => {
          setIsLoading(false);
          navigate("/signupcomplete");
        })
        .catch((err) => {
          setIsLoading(false);
          setOpenSnackbar(true);
          err.response
            ? setError(err.response.data.message)
            : setError("An error occured!");
          console.log(err);
        });
    } else {
    }
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div
        className="signupas d-flex justify-content-center text-center p-md-5 px-3 py-5"
        data-aos="fade-in"
      >
        <div className="bg-white rounded-lg col-md-6 p-5">
          <h3 className="fw-bold fs-3 py-4">Verify Email Address</h3>
          <p className="fs-6 pb-5">
            Kindly click on the Verify button below to verify your email address
          </p>
          <Button
            className={
              isLoading
                ? "bg-light border border-primary text-primary py-4 fw-bolder px-5"
                : "bg-webhostels px-5 py-4 fw-bold col-md-7"
            }
            disabled={isLoading}
            onClick={verifyEmail}
          >
            {isLoading ? "Verifying..." : "Verify Now"}
          </Button>
        </div>
        <Snackbar
          open={openSnackbar}
          onClose={handleClose}
          autoHideDuration={5000}
        >
          <Alert severity="error" variant="filled" onClose={handleClose}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Fragment>
  );
};

export default VerifyEmail;
