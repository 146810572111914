import React, { useState } from "react";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import logo from "../../assets/logosm.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signupSchema } from "../../validationschema";
import { Alert, Button } from "@mui/material";
import { useSelector } from "react-redux";

export const ClientSignUp = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const uri = useSelector((state) => state.ApiReducer.account_uri);
  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      mobile_number: "",
      password: "",
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setError("");
      axios
        .post(`${uri}user`, values)
        .then((res) => {
          console.log(res.data);
          navigate("/emailverification");
        })
        .catch((err) => {
          setIsLoading(false);
          !err.response
            ? setError(err.name)
            : setError(err.response.data.message);
        });
    },
  });

  return (
    <div className="md:pt-[72px] md:pl-[100px] md:pr-[108px] text-[#0C0D24] md:pb-[186px] pb-[32px] px-[22px] md:px-0">
      <img src={logo} alt="" className="md:hidden my-[32px] " />
      <h1 className=" font-bold md:mb-[48px] mb-[40px] md:text-[48px] text-3xl">
        Sign Up
      </h1>
      <form className="w-full md:mb-[83px] mb-[32px] ">
        {error !== "" && <Alert severity="error">{error}</Alert>}
        <div className="md:mb-[16px] mb-[20px] flex flex-col">
          <label htmlFor="fullName" className="md:mb-[8px] ">
            Full name
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            name="full_name"
            id="fullName"
            placeholder="Enter your full name"
            className="w-full h-[58px] md:h-[52px] pl-[20px] md:pl-[16px] border border-[#898C8F] placeholder:text-[#898C8F]text-[#898C8F] rounded-lg"
            required
          />
          {formik.errors.full_name && formik.touched.full_name && (
            <div className="text-danger">{formik.errors.full_name}</div>
          )}
        </div>
        <div className="md:mb-[16px] mb-[20px] flex flex-col">
          <label htmlFor="email" className="md:mb-[8px] ">
            Email address
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email address"
            className="w-full md:h-[52px] h-[58px] pl-[20px] md:pl-[16px] border border-[#898C8F] placeholder:text-[#898C8F]text-[#898C8F] rounded-lg"
            required
          />
          {formik.errors.email && formik.touched.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
        </div>
        <div className="md:mb-[16px] mb-[20px] flex flex-col">
          <label htmlFor="phoneNumber" className="md:mb-[8px] ">
            Phone number
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="mobile_number"
            id="phoneNumber"
            placeholder="Enter your phone number"
            className="w-full md:h-[52px] h-[58px] pl-[20px] md:pl-[16px] border border-[#898C8F] placeholder:text-[#898C8F]text-[#898C8F] rounded-lg"
            required
          />
          {formik.errors.mobile_number && formik.touched.mobile_number && (
            <div className="text-danger">{formik.errors.mobile_number}</div>
          )}
        </div>
        <div className="md:mb-[44px] mb-[32px] flex flex-col">
          <label htmlFor="password" className="md:mb-[8px] ">
            Password
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="password"
            name="password"
            id="password"
            placeholder="Minimum of 8 characters"
            className="w-full md:h-[52px] h-[58px] pl-[20px] md:pl-[16px] border border-[#898C8F] placeholder:text-[#898C8F]text-[#898C8F] rounded-lg"
            required
          />
          {formik.errors.password && formik.touched.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </div>
        {/* <Link to="/emailverification"> */}
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          className={
            isLoading
              ? "bg-light fw-bold w-100 py-3 border border-primary"
              : "bg-webhostels fw-bold w-100 py-3"
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span className="text-capitalize text-primary">
                Please wait...{" "}
              </span>
            </>
          ) : (
            <>Sign up</>
          )}
        </Button>
        {/* </Link> */}
      </form>
      <div className="md:flex hidden items-center justify-center md:gap-[16px] md:mb-[44px] ">
        <div className="bg-[#023047] md:w-[198px] md:h-[1px] "></div>
        <h6>OR</h6>
        <div className="bg-[#023047] md:w-[198px] md:h-[1px] "></div>
      </div>
      <p className="text-center md:mb-[44px] hidden md:block ">Sign up with</p>

      <div className="md:flex items-center justify-center md:gap-[48px] md:mb-[44px] hidden  ">
        <img src={google} alt="" />
        <h6>OR</h6>
        <img src={facebook} alt="" />
      </div>
      <p className="text-center">
        Already have an account?{" "}
        <a href="/login" className="text-primary">
          Login
        </a>
      </p>
    </div>
  );
};
