import React from "react";
import { FaWallet } from "react-icons/fa";
import { TbDiscount2 } from "react-icons/tb";
import { BsCalendarCheckFill } from "react-icons/bs";

const Blog = () => {
  return (
    <section className="flex-col items-center justify-center px-md-0 px-3">
      <div className="" data-aos="fade">
        <h5
          className="font-lato text-[35px] font-extrabold text-colora flex items-center justify-center"
          data-aos="zoom-out"
        >
          Why Choose Us
        </h5>
        <h2
          className="font-lato not-italic font-semibold text-[16px] md:text-[24px] text-text2 pt-3 flex items-center justify-center text-center px-md-0 px-2"
          data-aos="zoom-in"
        >
          We provide top knotch service at every step
        </h2>
      </div>
      <div className=" flex flex-col lg:flex-row gap-[1rem]   lg:gap-[2rem] items-center justify-center pt-[2.5rem] ">
        <div
          data-aos="zoom-out"
          className="cursor-pointer flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(61,63,180,0.05)] w-[95%] h-[95%] md:w-[380px] md:h-[380px] rounded-xl py-[1.6rem] px-[1.6rem] "
        >
          <div className="flex flex-col items-center gap-[1rem]">
            <FaWallet size={30} className="text-[#35369A]" />
            <div className="font-lato not-italic font-semibold text-[16px] lg:text-[24px] text-colora">
              Best Prices
            </div>
          </div>
          <div className="flex flex-col  gap-[1rem] ">
            <div className="font-lato flex flex-col items-center font-semibold not-italic text-[16px] text-[#515254]">
              <div className="font-semibold not-italic text-12px lg:text-[15px] text-[#515254] font-lato text-center">
                The hostels on our platform are affordable
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="zoom-out"
          className=" cursor-pointer flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(18,96,121,0.08)] w-[95%] h-[95%] md:w-[380px] md:h-[380px] rounded-xl py-[1.6rem] px-[1.6rem] "
        >
          <div className="flex flex-col items-center gap-[1rem]  ">
            <TbDiscount2 size={40} className="text-[#126079]" />
            <div className="font-lato not-italic font-semibold text-[24px] text-colora">
              Trusted By Many
            </div>
          </div>
          <div className="flex flex-col  gap-[1rem] ">
            <div className=" flex flex-col items-center">
              <div className="font-semibold not-italic text-[15px] text-[#515254] font-lato text-center">
                We have a track record of being reliable
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="zoom-out"
          className=" cursor-pointer flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(216,217,240,0.44)] w-[95%] h-[95%] md:w-[380px] md:h-[380px] rounded-xl py-[1.6rem] px-[1.6rem] "
        >
          <div className="flex flex-col items-center gap-[1rem]  ">
            <BsCalendarCheckFill size={40} className="text-[#126079]" />
            <div className="font-lato not-italic font-semibold text-[24px] text-colora">
              Quick Processes
            </div>
          </div>
          <div className="flex flex-col  gap-[1rem] ">
            <div className=" flex flex-col items-center">
              <div className="font-semibold not-italic text-[15px] text-[#515254] font-lato text-center">
                Our main goal is to connect our clients to reliable merchants to
                give you the absolute best
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
