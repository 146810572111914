import React from "react";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import logo from "../../assets/logosm.svg";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useFormik } from "formik";
import { loginSchema } from "../../validationschema";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uri = useSelector((state) => state.ApiReducer.account_uri);
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      userType: "CUSTOMER_USER",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setError("");
      setIsLoading(true);
      await axios({
        method: "post",
        url: `${uri}login`,
        data: values,
      })
        .then((res) => {
          setIsLoading(false);
          dispatch({ type: "setUser", payload: "Logged in" });
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
          localStorage.setItem("refreshToken", JSON.stringify(res.data.data.refreshToken));
          setResponse(
            `Hi, ${res.data.data.account.fullName}!\n${res.data.message}`
          );
          setOpenDialog(true);
        })
        .catch((err) => {
          setIsLoading(false);
          !err.response
            ? setError(err.message)
            : setError(err.response.data.message);
        });
    },
  });

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div
      className="md:pt-[72px] md:pl-[100px] md:pr-[108px] text-[#0C0D24] md:pb-[186px] pb-[32px] px-[22px] md:px-0"
      data-aos="fade-in"
    >
      <h1 className=" font-bold md:mb-[20px] md:text-[48px] hidden md:block  ">
        Welcome back!
      </h1>
      <img src={logo} alt="" className="md:hidden my-[32px] " />
      <h1 className="md:hidden font-bold md:mb-[48px] text-3xl w-[316px] pb-4">
        Let's help Find your New space
      </h1>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full md:mb-[83px] mb-[32px]"
      >
        {error !== "" && (
          <Alert severity="error" className="fw-bold mb-4">
            {error}
          </Alert>
        )}
        <div className="md:mb-[16px] mb-[35px] flex flex-col">
          <label htmlFor="email" className="md:mb-[8px] ">
            Email address
          </label>
          <input
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="email"
            placeholder="Enter your email address"
            className="md:w-[502px] w-full h-[54px] md:h-[52px] pl-[20px] md:pl-[16px] border border-[#898C8F] placeholder:text-[#898C8F]text-[#898C8F] rounded-lg"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
        </div>
        <div>
          <label htmlFor="Password" className="md:mb-[8px] ">
            Password
          </label>
          <FormControl className="w-100 mb-4 px-0 mx-0" variant="outlined">
            <InputLabel htmlFor="resetPword">Password</InputLabel>
            <OutlinedInput
              id="resetPword"
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {formik.errors.password && formik.touched.password && (
              <div className="text-danger">{formik.errors.password}</div>
            )}
          </FormControl>
        </div>
        <p
          className="text-end pb-4 cursor-pointer text-webhostels"
          onClick={() => navigate("/verify/forgot-password")}
        >
          Forgot password?
        </p>
        {/* </div> */}
        <Button
          type="submit"
          className={
            isLoading
              ? "bg-light border border-primary text-primary w-100 py-3 fw-bolder"
              : "bg-webhostels w-100 fw-bolder py-3"
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <p className="text-capitalize">Please wait...</p>
          ) : (
            "Log in"
          )}
        </Button>
      </form>
      <div className=" items-center justify-center md:gap-[16px] md:mb-[24px] hidden md:flex ">
        <div className="bg-[#023047] md:w-[198px] md:h-[1px] "></div>
        <h6>OR</h6>
        <div className="bg-[#023047] md:w-[198px] md:h-[1px] "></div>
      </div>
      <p className="text-center md:mb-[24px] hidden md:block ">Log in with</p>

      <div className=" items-center justify-center md:gap-[48px] md:mb-[44px] md:flex hidden ">
        <p className="py-2 px-4 shadow rounded-pill d-flex justify-content-center">
          <img src={google} alt="" /> <span className="pt-3 px-1">Google</span>
        </p>
        <h6>OR</h6>
        <p className="shadow text-center p-3 rounded-pill d-flex justify-content-center">
          <img src={facebook} alt="" className="img-fluid" />{" "}
          <span className="pt-3 px-1">Facebook</span>
        </p>
      </div>
      <p className="text-center md:mt-[0] mt-[30px] ">
        Don't have an account?{" "}
        <a href="/signup" className="text-primary">
          Sign Up
        </a>
      </p>

      <Dialog open={openDialog} maxWidth={"xs"} fullWidth={true}>
        <DialogContent>
          <DialogTitle className="text-center px-0">
            {response.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </DialogTitle>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => navigate("/products")}
              className="btn-webhostels"
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SignIn;
