import React from "react";
import ava7 from "../../assets/about.png";
import group from "../../assets/group.png";
import { Button } from "@mui/material";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";
import { Fragment } from "react";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="flex flex-col md:justify-center md:items-center gap-[6rem]">
        <div className="about container-fluid px-md-5 px-4">
          <div className="row w-100 ">
            <div className="col-md-6 py-5" data-aos="zoom-out">
              <p className="fw-bold fs-1 pt-md-5">
                About <span className="text-webhostels">WebHostelsNg</span>
              </p>
              <p className="fs-5 py-4 text-justify">
                Webhostelsng is a platform that is solving hostel hunting
                problems among undergraduates in Nigeria. We also provide
                short-letting services for Post-UTME students, guests and
                visitors
              </p>
              <Button
                onClick={() => navigate("/signupas")}
                className="btn-webhostels px-4 py-3"
              >
                Get Started
              </Button>
            </div>
            <div className="col-md-6" data-aos="fade-in">
              <img src={group} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[1rem] md:gap-[4rem] items-center justify-center ">
          <div
            className="font-lato not-italic text-[45px] font-bold text-black"
            data-aos="slide-up"
          >
            What we do
          </div>
          <div
            className="flex md:flex-row flex-col items-center justify-center gap-[1rem] md:gap-[6rem]"
            data-aos="zoom-out"
          >
            <div className="flex md:hidden  text-center w-[350px] px-4 font-lato not-italic font-[500] text-[14px] text-[#504F4F]">
              At Webhostelsng, we ease the stress of hostel hunting and present
              you with variety of comfortable and affordable hostels for you to
              make your choice. We also help our clients make money on our
              platform when they lease their space for our short-letting
              service.
            </div>
            <div className="px-[0.5rem] flex items-center justify-center">
              <img
                src={ava7}
                className="w-[90%] md:w-[500px] md:h-[500px]"
                alt=""
              />
            </div>
            <div className="hidden md:flex w-[400px] font-lato not-italic font-[500] text-[25px] text-[#504F4F]">
              At Webhostelsng, we ease the stress of hostel hunting and present
              you with variety of comfortable and affordable hostels for you to
              make your choice. We also help our clients make money on our
              platform when they lease their space for our short-letting
              service.
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[4rem] md:items-center md:justify-center testimonials pb-5 container-fluid">
          <div className="fw-bold fs-1 text-center pt-4">
            See testimonials of others
          </div>
          <div className=" overflow-y-hidden no-scrollbar overflow-x-scroll md:overflow-x-hidden   ">
            <div className=" flex justify-center items-center  w-fit gap-4 pt-[2.5rem]  ">
              <div className=" flex-col items-center justify-center  gap-4  border-[1px] md:px-[1rem] md:py-[1.5rem] md:h-[300px] md:w-[280px] w-[250px] h-[200px] ">
                <div className=" flex flex-col items-center justify-center gap-3   ">
                  <div className=" py-[0.5rem] px-[1rem] md:px-[1rem]  items-center justify-center bg-colorb  rounded-full ">
                    <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                      D
                    </div>
                  </div>
                  <div className="font-sans font-semibold  text-[16px] not-italic">
                    David Mark
                  </div>
                </div>
                <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  md:text-[16px]  text-center flex items-center ">
                  Having the ticked app on my phone has made me manage my task
                  well.I'm always up-to-date on my tasks.
                </div>
              </div>

              <div className=" flex-col  gap-4  border-[1px] md:px-[1rem] md:py-[1.5rem] md:h-[300px] md:w-[280px] w-[270px] h-[200px] ">
                <div className=" flex  items-center justify-center gap-3 flex-col ">
                  <div className=" py-[0.5rem] px-[1rem]  bg-[#FDA758]  rounded-full items-center justify-center">
                    <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                      A
                    </div>
                  </div>
                  <div className="font-sans font-semibold  text-[16px] not-italic">
                    Adorable Grace
                  </div>
                </div>
                <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  md:text-[16px]  text-center flex items-center ">
                  Working with this team has been amazing, i'm happy witjh the
                  progress we made building something individuals are happy
                  using. Well done team..
                </div>
              </div>

              <div className=" flex-col  gap-4 items-center justify-center border-[1px] md:px-[1rem] md:py-[1.5rem] md:h-[300px] md:w-[280px] w-[270px] h-[200px] ">
                <div className=" flex  items-center justify-center gap-3 flex-col ">
                  <div className=" py-[0.5rem] px-[1rem] bg-[#FDA758] rounded-full items-center justify-center">
                    <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                      R
                    </div>
                  </div>
                  <div className="font-sans font-semibold  text-[16px] not-italic">
                    Ruth Davidson
                  </div>
                </div>
                <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  md:text-[16px]  text-center flex items-center ">
                  This has been the app I would say i needed . I love the
                  interface and how easy it is for me to access the features
                  provided. Good job guys I will highly recommend.
                </div>
              </div>

              <div className=" flex-col items-center justify-center gap-4  border-[1px] md:px-[1rem] md:py-[1.5rem] md:h-[300px] md:w-[280px] w-[270px] h-[200px] ">
                <div className=" flex  items-center justify-center gap-3 flex-col ">
                  <div className=" py-[0.5rem] px-[1rem]  bg-colorb  rounded-full items-center justify-center">
                    <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                      E
                    </div>
                  </div>
                  <div className="font-sans font-semibold  text-[14px] not-italic">
                    Esther George
                  </div>
                </div>
                <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  md:text-[16px]  text-center flex items-center ">
                  I must commened the efforts use to build this great app. Well
                  done guys.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default About;
