import { Backdrop, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CheckMail = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const email = JSON.parse(sessionStorage.getItem("email"));
  const uri = useSelector((state) => state.ApiReducer.account_uri);

  const openMail = () => {
    window.open(`mailto:`);
  };
  const resendMail = () => {
    setIsLoading(true);
    axios
      .post(`${uri}forgot-password`, email)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        err.response
          ? alert(err.response.data.message)
          : alert("An error has occured!");
        console.log(err);
      });
  };

  useEffect(() => {
    !email ? navigate("/verify/forgot-password") : console.log("nothing");
  }, []);
  return (
    <Fragment>
      <div className="d-flex justify-content-center px-4" data-aos="fade-in">
        <div className="col-lg-5 col-md-7 py-5">
          <p className="text-center fs-3 fw-bold">Check your mail</p>
          <p className="text-center py-4">
            We sent you a password reset link to {email.email}
          </p>
          <Button onClick={openMail} className="bg-webhostels w-100 py-3">
            open email app
          </Button>
          <p className="text-center py-2">
            Didn't receive any email?{" "}
            <span
              onClick={resendMail}
              className="text-webhostels cursor-pointer"
            >
              Click to resend
            </span>
          </p>
          <p className="text-center pt-2">
            <a href="/login" className="">
              <i className="fa fa-arrow-left"></i>Back to Login
            </a>
          </p>
        </div>
      </div>
      {/* Backdrop */}
      <Backdrop open={isLoading}>
        <span className="spinner-border text-white"></span>
      </Backdrop>
    </Fragment>
  );
};

export default CheckMail;
