import { useEffect } from "react";
import { BsPeopleFill } from "react-icons/bs";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

const Service = () => {
  useEffect(() => {});
  return (
    <section className="pb-5 px-md-0 px-2" data-aos="fade-up">
      <h5
        className="font-lato text-[24px] lg:text-[35px] font-extrabold text-colora"
        data-aos="zoom-out"
      >
        Our Services
      </h5>
      <h2
        className="font-lato not-italic font-semibold fs-5 py-4 text-muted px-2"
        data-aos="zoom-in"
      >
        We are committed to helping our clients reach their goals
      </h2>
      <div className="flex gap-10 flex-col items-center justify-center px-md-0">
        <div
          data-aos="zoom-out"
          className="flex flex-col lg:flex-row  items-center justify-center gap-[2rem]"
        >
          <div className=" flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(216,217,240,0.24)] w-[90%] h-[80%] md:w-[380px] md:h-[380px] rounded-xl py-[1.8rem] px-[1.8rem] ">
            <div className="flex flex-col items-center gap-[1rem]  ">
              <BsFillHouseDoorFill size={30} className="text-[#35369A]" />
              <div className="font-lato not-italic font-semibold text-[24px] text-colora text-center">
                Top Notch Hostels
              </div>
            </div>
            <div className="flex flex-col  gap-[1rem] ">
              <div className="font-lato flex flex-col items-center font-semibold not-italic text-[16px] text-[#515254]">
                <div className="font-semibold not-italic text-[15px] text-[#515254] font-lato text-center w-[75%]">
                  We make hostel hunting easier and present our client comfortable and most affordable hostels
                </div>
              </div>
              <div className="cursor-pointer flex gap-[0.5rem] items-center justify-center">
                <div className="font-lato text-colorb font-semibold text-[18px] cursor-pointer">
                  Learn More
                </div>
                <BsArrowRight size={25} className="text-colorb" />
              </div>
            </div>
          </div>

          <div
            data-aos="zoom-out"
            className=" flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(253,167,88,0.16)] w-[95%] h-[100%] md:w-[380px] md:h-[380px] rounded-xl py-[1.6rem] px-[1.6rem] "
          >
            <div className="flex flex-col items-center gap-[1rem]  ">
              <BsPeopleFill size={30} className="text-[#DE700A]" />
              <div className="font-lato not-italic font-semibold text-[24px] text-colora text-center">
                Reliable Agents
              </div>
            </div>
            <div className="flex flex-col  gap-[1rem] ">
              <div className=" flex flex-col items-center">
                <div className="font-semibold not-italic text-[15px] text-[#515254] font-lato w-[75%] text-center">
                  Our agents are reliable and can be trusted. You can bank on us.
                </div>
              </div>
              <div className="cursor-pointer flex gap-[0.5rem] items-center justify-center">
                <div className="font-lato text-colorb font-semibold text-[18px] cursor-pointer">
                  Learn More
                </div>
                <BsArrowRight size={25} className="text-colorb" />
              </div>
            </div>
          </div>

          <div
            data-aos="zoom-out"
            className=" flex flex-col items-center justify-center gap-[1.6rem] bg-[rgba(61,63,180,0.24)] w-[95%] h-[100%] md:w-[380px] md:h-[380px] rounded-xl py-[1.8rem] px-[1.8rem] "
          >
            <div className="flex flex-col items-center gap-[1rem]  ">
              <BsFillHouseDoorFill size={30} className="text-[#35369A]" />
              <div className="font-lato not-italic font-semibold text-[24px] text-colora text-center">
                Amazing Products
              </div>
            </div>
            <div className="flex flex-col  gap-[1rem] ">
              <div className="font-lato flex flex-col items-center font-semibold not-italic text-[16px] text-[#515254]">
                <div className="font-semibold not-italic text-[15px] text-[#515254] font-lato text-center w-[75%]">
                  We have built this product for you to ease hostel hunting.
                </div>
              </div>
              <div className="cursor-pointer flex gap-[0.5rem] items-center justify-center">
                <div className="font-lato text-colorb font-semibold text-[18px] cursor-pointer">
                  Learn More
                </div>
                <BsArrowRight size={25} className="text-colorb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
