import { Button, Dialog, DialogContent } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Logo from "../../assets/logo-white.png";
import { useLocation, useNavigate } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import { useSelector } from "react-redux";
import axios from 'axios'

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const goHome = () => navigate("/");
  const baseUri = useSelector((state) => state.ApiReducer.base_uri);
  const location = useLocation();
  const [hostelName, setHostelName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setIsError(false)
    const param = new URLSearchParams(location.search).get("tx_ref");
    const trx_id = new URLSearchParams(location.search).get("transaction_id")
    if (trx_id !== null){
          axios.get(
            `${baseUri}transaction/${param}/verify/${trx_id}`,
            {
              headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              }
            }
          ).then(res=>{
            setIsLoading(false)
            setHostelName(res.data.data.hostelName)
            console.log(res.data)
          }).catch(err=>{
            console.log(err)
            setIsError(true)      
          })
    }else navigate('/products')
    // if (hostelName !== null) {
    // } else {
    //     navigate('/apartment')
    // }
  }, [reload]);

  return (
    <Fragment>
      {
        isLoading
        ?
        <Dialog
          open={isLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent>
            {isError ? (
              <>
                <p className="text-danger fs-5 pb-4 fw-bold">Internal Server Error</p>
                <div className='d-flex justify-content-center'>
                  <button
                    onClick={() => (reload ? setReload(false) : setReload(true))}
                    className="rounded-pill btn btn-dark"
                  >
                    Reload page
                  </button>
                </div>
              </>
            ) : (
              <p>
                <span className="spinner-border text-white"></span>
              </p>
            )}
          </DialogContent>
        </Dialog>
        :
        <div className="container-fluid">
          <div className="d-flex justify-content-start mb-5">
            <img src={Logo} className="img-fluid" />
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="d-flex justify-content-center">
                <div className="p-3 rounded-circle bg-webhostels">
                  <BiCheck className="text-white fs-2" />
                </div>
              </div>
              <p className="fw-bold fs-4 text-center pt-3 pb-1">
                Payment Successful
              </p>
              <p className="text-center py-1">
                Your payment to secure the {hostelName} apartment has been placed
                successfully. Check your mail for the supporting documents of your new apartment.
              </p>
              <div className="d-flex justify-content-center my-2">
                <Button onClick={goHome} className="btn-webhostels px-5">
                  Go Back Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default PaymentSuccess;
