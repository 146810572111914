import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import { useNavigate, useParams } from "react-router-dom";
import Properties from "../Landingpage/properties/Properties";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import { Box, Button, Dialog, DialogContent, Tab, Tabs } from "@mui/material";
import { HiLocationMarker } from "react-icons/hi";
import { NumericFormat } from "react-number-format";

const ViewDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [isError, setIsError] = useState(false);
  const uri = useSelector((state) => state.ApiReducer.apartment_uri);
  const baseUri = useSelector((state) => state.ApiReducer.base_uri);
  const [details, setDetails] = useState({ yearlyRates: "" });
  const [value, setValue] = useState("one");
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [trxLoading, setTrxLoading] = useState(false);
  const [trxError, setTrxError] = useState(false);

  useEffect(() => {
    setIsError(false);
    axios
      .get(`${uri}${id}`)
      .then((res) => {
        setIsLoading(false);
        setDetails(res.data.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      });
  }, [reload]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkOut = () => {
    if (token !== null) {
      setTrxLoading(true);
      setTrxError(false);
      const payload = { apartmentId: details.id };
      axios
        .post(`${baseUri}transaction`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          window.location.replace(
            res.data.data.paymentGatewayResponse.link
          );
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.message.includes('expired')) {
              alert(err.response.data.message)
              navigate('/products')
            } else {
              setTrxError(true);
            }
          } else setTrxError(true);
          console.log(err)
        });
    } else {
      alert("You need to LOG IN first");
      navigate("/login");
    }
  };

  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      {isLoading ? (
        <Dialog
          open={isLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent>
            {isError ? (
              <>
                <p className="text-danger fs-7">Internal Server Error</p>
                <button
                  onClick={() => (reload ? setReload(false) : setReload(true))}
                  className="rounded-pill btn btn-dark"
                >
                  Reload page
                </button>
              </>
            ) : (
              <p>
                <span className="spinner-border text-white"></span>
              </p>
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div className="container-fluid px-md-5 px-2">
            <div className="px-md-5">
              <p>
                <span className="fs-3 text-capitalize">{details.name}</span>
                <span className="ps-5">{details.address.streetAddress}</span>
              </p>
            </div>
            <p className="fs-6 py-2 px-md-5">
              <b>Description of the apartment:</b> {details.description}
            </p>
            <div className="d-flex flex-md-row flex-column px-md-5 px-2 my-3">
              {details.tags.map((each, i) => (
                <div
                  key={i}
                  className="border-webhostels px-5 py-3 me-md-5 rounded-3 mb-2"
                >
                  {each}
                </div>
              ))}
            </div>
            <div className='d-flex flex-md-row flex-column'>
              <p className="fs-4 px-md-5 pt-3">
                Price: <NumericFormat value={details.yearlyRates} prefix="₦" displayType="text" thousandSeparator={true} />/yr
              </p>            
              <p className="fs-4 pt-3">
                Charges: <NumericFormat value={'5000'} prefix="₦" displayType="text" thousandSeparator={true} />
              </p>
              <p className="fs-4 pt-3 px-md-5">
                Commission: <NumericFormat value={details.otherFees} prefix="₦" displayType="text" thousandSeparator={true} />
              </p>
            </div> 
            <div className="px-md-5 my-2">
              <Button onClick={checkOut} className="btn-webhostels px-5 py-3">
                Checkout
              </Button>
            </div>
            <Box className="px-md-5 px-2 my-3" sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
              >
                <Tab value="one" label="Videos" wrapped />
                <Tab value="two" label="Images" />
              </Tabs>
            </Box>
            <div className="px-md-5 px-2">
              {value == "one" ? (
                <video controls>
                  <source src={details.videos[0]} />
                </video>
              ) : (
                <div className="row mx-auto">
                  {details.images.map((each, i) => (
                    <div key={i} className="col-md-4 mb-4">
                      <img src={each} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Properties />
          <Footer />
        </div>
      )}
      {trxLoading && (
        <Dialog
          open={trxLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent>
            {trxError ? (
              <>
                <p className="text-danger fs-6 py-2">Transaction Error</p>
                <button
                  onClick={checkOut}
                  className="rounded-pill btn btn-dark"
                >
                  Try Again
                </button>
              </>
            ) : (
              <p>
                <span className="spinner-border text-white"></span>
              </p>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ViewDetails;
