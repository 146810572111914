import React, { Fragment, useEffect } from "react";
import img1 from "../../../assets/newbg.png";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const uri = useSelector((state) => state.ApiReducer.account_uri);

  useEffect(()=>{
    const payload = {refresh_token: JSON.parse(localStorage.getItem('refreshToken'))}
    axios.post(
      `${uri}token/refresh`,
      payload
    ).then(res=>{
      localStorage.setItem('token', JSON.stringify(res.data.data.token))
    }).catch(err=>{
      console.log(err)
    })
  }, [])
  return (
    <Fragment>
      <div className="home mx-0 px-0">
        <div className="row w-100 mx-0" data-aos="fade-up">
          <div className="col-md-5 p-md-5 px-2 py-3 mt-md-5">
            <p className="fs-1 fw-bold px-3 pt-3" data-aos="zoom-out">
              Let's Find your Dream Apartment
            </p>
            <p className="fs-6 p-3" data-aos="zoom-in">
              We make finding hostels easier and cheaper. We make and help your
              experience swift and enjoyable. Webhostelsng gives the best and
              most reliable merchants.
            </p>
            <Button
              onClick={() => navigate("/signupas")}
              className="btn-webhostels px-4 py-3 mx-3"
            >
              Get Started
            </Button>
          </div>
          <div className="col-md-7 px-md-0 px-4" data-aos="fade">
            <img src={img1} className="img-fluid" alt="house" />
          </div>
        </div>
        <div className="d-md-block d-none">
          <div className="d-flex justify-content-center home-bottom-box">
            <div className="bg-white col-md-6 shadow-lg rounded-3 p-4">
              <div className="d-flex justify-content-around">
                <div className="d-flex">
                  <div className="px-2">
                    <img
                      src={require("../../../assets/home-2.jpg")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <p className="fw-bold">Location</p>
                    <p>Ile-Ife, Nigeria</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="px-2">
                    <img src={require("../../../assets/home-2.jpg")} />
                  </div>
                  <div>
                    <p className="fw-bold">House Type</p>
                    <p>Self Contain</p>
                  </div>
                </div>
                <div>
                  <Button className="btn-webhostels px-3 py-3">
                    <span className="pe-2">
                      <BiSearch className="" />
                    </span>{" "}
                    <span>Search</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
