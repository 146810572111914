import React from "react";
import { useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { BsBookmarkDash } from "react-icons/bs";
import house1 from "../../../assets/house1.png";

const Properties = () => {
  const [tray, setTray] = useState([1, 2, 3, 4]);
  return (
    <div className="pt-5" data-aos="fade-up">
      <p className="fs-2 fw-bold text-center" data-aos="zoom-out">
        Our Featured Properties
      </p>
      <p
        className="text-muted text-center fs-5 py-3 px-md-0 px-2"
        data-aos="zoom-in"
      >
        Our unique process gives you peace of mind from home rent to service
      </p>
      <div className="container py-5">
        <div className="row w-100 px-md-5 mx-auto">
          {tray.map((each, i) => (
            <div key={i} className="col-md-6 px-lg-4 mb-4" data-aos="zoom-in">
              <div className="border-colorb border-[1px] py-[2rem] px-[2rem] rounded-lg transition-ease duration-500">
                <div className="d-flex flex-lg-row flex-column gap-[2rem] ">
                  <img
                    src={house1}
                    alt="property"
                    className="rounded-lg img-fluid col-md-6"
                  />
                  <div className="flex flex-col md:gap-3 pt-1 ">
                    <div className="d-flex justify-content-between font-lato not-italic font-semibold text-[16px] text-colorb">
                      Self contain
                      <div className="ms-5 h-[32px] w-[32px] bg-[#D8D9F0] flex items-center justify-center ">
                        <BsBookmarkDash
                          size={20}
                          className="text-colorb cursor-pointer transition-ease duration-500"
                        />
                      </div>
                    </div>
                    <div className="font-lato not-italic font-semibold text-[20px] text-colora">
                      NGN150000
                    </div>
                    <div className="flex items-center  gap-3">
                      <HiLocationMarker className="text-colorb" size={20} />
                      <div className="font-lato font-semibold text-[14]">
                        parakin
                      </div>
                    </div>
                    <button className="mt-2 mt-md-4 cursor-pointer md:mt-[2rem] py-[1rem] px-[1rem] bg-[#fff] border-2 border-colorb font-lato font-semibold text-[12px] text-colorb rounded-md transition-ease duration-500 hover:bg-colorb hover:text-[#fff]">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Properties;
