import React, { useEffect } from "react";
import Home from "../Landingpage/home/Home";
import Service from "../Landingpage/services/Service";
import Properties from "../Landingpage/properties/Properties";
import Blog from "../Landingpage/blog/Blog";
import Testimonials from "./testimonials/Testimonials";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import Footer from "../footer/Footer";

const Landingpage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div>
      <Navbar />
      <Scrollup />
      <Home />
      <Service />
      <Properties />
      <Blog />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Landingpage;
