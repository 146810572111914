import React, { Fragment, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useFormik } from "formik";
import { resetpasswordSchema } from "../../validationschema";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const ResetPassword = () => {
  const uri = useSelector((state) => state.ApiReducer.account_uri);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { id, token } = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { handleBlur, handleChange, handleSubmit, errors, touched } = useFormik(
    {
      initialValues: {
        password: "",
        confirm_password: "",
      },
      validationSchema: resetpasswordSchema,
      onSubmit: (values) => {
        setError("");
        setIsLoading(true);
        const payload = { token, password: values.password };
        axios
          .post(`${uri}reset-password`, payload)
          .then((res) => {
            console.log(res.data);
            setIsLoading(false);
            navigate("/verify/success");
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            err.response
              ? setError(err.response.data.message)
              : setError("Internal Server Error");
          });
      },
    }
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-center px-4" data-aos="fade-in">
        <div className="col-lg-5 col-md-7 py-5">
          <p className="text-center fs-3 fw-bold text-dark">
            Create new password
          </p>
          <p className="text-center">
            New password must be different from previously used passwords
          </p>
          <FormControl className="w-100 my-4" variant="outlined">
            <InputLabel>New Password</InputLabel>
            <OutlinedInput
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
            {errors.password && touched.password && (
              <div className="text-danger">{errors.password}</div>
            )}
          </FormControl>
          <FormControl className="w-100 mb-4" variant="outlined">
            <InputLabel>Confirm Password</InputLabel>
            <OutlinedInput
              name="confirm_password"
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
            {errors.confirm_password && touched.confirm_password && (
              <div className="text-danger">{errors.confirm_password}</div>
            )}
          </FormControl>
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            className="bg-webhostels w-100 text-white fw-bold font-poppins"
          >
            {isLoading ? "Please wait..." : "reset password"}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
