import Landingpage from "./components/Landingpage/Landingpage";
import AuthLayout from "./layouts/AuthLayout";
import SignIn from "../src/pages/auth/SignIn";
import { ClientSignUp } from "./pages/auth/ClientSignUp";
import EmailAuth from "./pages/email/EmailAuth";
import SignUpComplete from "./pages/auth/SignUpComplete";
import Products from "./components/products/Products";
import About from "./components/about/About";
import VerifyEmail from "./pages/email/VerifyEmail";
import BestDescribe from "./components/signup/BestDescribe";
import ContactUs from "./components/contact/ContactUs";
import ViewDetails from "./components/products/ViewDetails";
import PaymentSuccess from "./components/products/PaymentSuccess";

export const RoutesObjects = [
  {
    id: 1,
    path: "/",
    element: <Landingpage />,
  },
  {
    id: 2,
    path: "/login",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  {
    id: 3,
    path: "/signup",
    element: (
      <AuthLayout>
        <ClientSignUp />
      </AuthLayout>
    ),
  },
  {
    id: 5,
    path: "/signupas",
    element: <BestDescribe />,
  },
  {
    id: 7,
    path: "/emailverification",
    element: <EmailAuth />,
  },
  {
    id: 8,
    path: "/signupcomplete",
    element: <SignUpComplete />,
  },
  {
    id: 9,
    path: "/products",
    element: <Products />,
  },
  {
    id: 10,
    path: "/about",
    element: <About />,
  },
  {
    id: 11,
    path: "/:userType/verification/:id/:token",
    element: <VerifyEmail />,
  },
  {
    id: 12,
    path: "/contact",
    element: <ContactUs />,
  },
  {
    id: 13,
    path: "/apartment/:id",
    element: <ViewDetails />,
  },
  {
    id: 14,
    path: "/pay/verify",
    element: <PaymentSuccess />,
  },
];
