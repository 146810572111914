import React from "react";
import logo from "../assets/logo.svg";
import img from "../assets/img.svg";
import { Fragment } from "react";
import Navbar from "../components/navbar/Navbar";
import Scrollup from "../components/scrolltotop/Scrollup";
import Footer from "../components/footer/Footer";

function AuthLayout({ children }) {
  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="md:flex ">
        <div className="md:w-[50%] w-full">{children}</div>
        <div className="bg-[#3D3FB4]  hidden md:block w-[50%] ">
          <img
            src={logo}
            alt=""
            className="md:mb-[20px] px-[50px] py-[20px] "
          />
          <div className="flex justify-center md:mb-[50px] ">
            <img src={img} alt="" />
          </div>
          <h1 className="text-center md:text-[48px] font-bold text-white">
            Let's Help You Find Your New Space
          </h1>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default AuthLayout;
