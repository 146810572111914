import React, { Fragment } from "react";
import logo from "../../assets/logosm.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "../navbar/Navbar";
import Scrollup from "../scrolltotop/Scrollup";
import Footer from "../footer/Footer";

const BestDescribe = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="signupas py-5 px-3" data-aos="fade-in">
        <h1 className="text-center fs-1 d:text-5xl font-bold md:mb-[16px] hidden md:block text-webhostels">
          Sign Up As
        </h1>
        <img src={logo} alt="" className="py-[32px] md:hidden " />
        <p className="text-center fs-2 text-webhostels">
          Which of these best describes you?
        </p>
        <div className="row px-md-5 w-100 my-md-5 my-2 mx-auto">
          <div className="col-md-5">
            <div className="bg-white rounded-3 p-md-5 p-3 text-center">
              <h3 className="md:text-2xl font-semibold text-dark pt-2">
                Client
              </h3>
              <p className="mt-4">
                This is the student or customer looking for an apartment or
                place to lodge at.
              </p>
              <Button
                onClick={() => navigate("/signup")}
                className="bg-webhostels px-5 py-3 my-0 mt-md-5 mt-3"
              >
                Choose
              </Button>
            </div>
          </div>
          <div className="col-md-2 my-md-0 my-2"></div>
          <div className="col-md-5">
            <div className="bg-white rounded-3 p-md-5 p-3 text-center">
              <p className="md:text-2xl font-semibold text-dark h3 pt-2">
                Merchant
              </p>
              <p className="mt-4">
                This is the agent that connects the student to the apartment or
                place to lodge at.
              </p>
              <Button
                onClick={() =>
                  window.open('https://merchant.webhostelsng.com')
                }
                className="bg-webhostels px-5 py-3 mt-md-5 mt-3"
              >
                Choose
              </Button>
            </div>
          </div>
        </div>
        <p className="text-white text-center">
          Already have an account?{" "}
          <span onClick={() => navigate("/login")} className="cursor-pointer">
            Login
          </span>
        </p>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BestDescribe;
