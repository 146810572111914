import { Button } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const ResetSuccessful = () => {
  const navigate = useNavigate();
  const Continue = () => {
    navigate("/login");
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-center px-4" data-aos="fade-in">
        <div className="col-lg-5 col-md-7 py-5">
          <p className="text-center fs-3 fw-bold">
            Password reset successfully
          </p>
          <p className="text-center py-4">
            Your password has been reset successfully. Continue to login.
          </p>
          <Button onClick={Continue} className="bg-webhostels w-100">
            continue
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetSuccessful;
