import { Button } from "@mui/material";
import React from "react";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Fragment } from "react";
import Navbar from "../../components/navbar/Navbar";
import Scrollup from "../../components/scrolltotop/Scrollup";
import Footer from "../../components/footer/Footer";

const EmailAuth = () => {
  const handleOpenEmailClient = () => {
    window.location.href = "mailto:";
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div className="signupas py-md-5 py-3">
        <div className="d-flex justify-content-center p-md-5 p-3">
          <div className="col-md-6 bg-white p-md-5 px-3 py-5 rounded-5">
            <h3 className="md:text-4xl text-3xl font-semibold mb-[20px] md:mb-[40px] text-center">
              Almost Done...
            </h3>
            <p className="text-center fs-5 text-base pb-4">
              Confirm your email address, kindly go to your email inbox, a
              message is waiting for you.
            </p>
            <div className="d-flex justify-content-center my-3">
              <Button
                className="bg-webhostels px-5 py-3 fw-bold"
                onClick={handleOpenEmailClient}
              >
                Go to mail
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default EmailAuth;
