import React, { Fragment, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../validationschema";
import axios from "axios";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const uri = useSelector((state) => state.ApiReducer.account_uri);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { handleBlur, handleChange, handleSubmit, errors, touched } = useFormik(
    {
      initialValues: {
        email: "",
      },
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => {
        setIsLoading(true);
        setMessage("");
        axios
          .post(`${uri}forgot-password`, values)
          .then((res) => {
            sessionStorage.setItem("email", JSON.stringify(values));
            setIsLoading(false);
            navigate("/verify/checkMail");
          })
          .catch((err) => {
            err.response
              ? setMessage(err.response.data.message)
              : setMessage("An error has occured!");
            setIsLoading(false);
            console.log(err);
          });
      },
    }
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-center px-4" data-aos="fade-in">
        <div className="col-lg-5 col-md-7 py-5">
          <p className="text-center fs-3 fw-bold">Forgot Password</p>
          <p className="text-center">
            Enter the email associated with your account and we will send an
            email containing password recovery instruction.
          </p>
          {message !== "" && (
            <div className="alert alert-danger alert-dismissible font-poppins mt-1">
              <i className="fa fa-exclamation-triangle"></i> {message}
            </div>
          )}
          <div className="my-4">
            <label>Email Address</label>
            <input
              className="form-control py-3"
              placeholder="youremail@gmail.com"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email && (
              <div className="text-danger font-poppins">{errors.email}</div>
            )}
          </div>
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            className={
              isLoading
                ? "border border-primary w-100 text-primary py-2"
                : "bg-webhostels w-100 py-2"
            }
          >
            {isLoading ? "submitting..." : "submit"}
          </Button>
          <p
            className="text-center pt-3 text-webhostels cursor-pointer"
            onClick={() => navigate("/login")}
          >
            <i className="fa fa-arrow-left"></i>Back to Login
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
