import React from "react";
import { Button } from "@mui/material";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import Navbar from "../../components/navbar/Navbar";
import Scrollup from "../../components/scrolltotop/Scrollup";
import Footer from "../../components/footer/Footer";

const SignUpComplete = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Fragment>
      <Navbar />
      <Scrollup />
      <div
        className="d-flex justify-content-center signupas p-md-5 px-3 py-5"
        data-aos="fade-in"
      >
        <div className="bg-white p-5 rounded-4 col-md-6 col-12 mx-2 mx-md-0 text-center my-md-5">
          <h3 className="fs-3 fw-bold py-4">Sign Up Completed</h3>
          <p className="fs-6 pb-4">You have successfully signed up</p>
          <Button
            onClick={() => navigate("/login")}
            className="btn-webhostels py-3 px-4 col-8 mb-5"
          >
            Continue
          </Button>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignUpComplete;
