import React from "react";
import log2a from "../../assets/log2a.png";
import log2b from "../../assets/log2b.png";
import { RiFacebookFill } from "react-icons/ri";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { MdOutlineCopyright } from "react-icons/md";
import { Alert, Button, Snackbar } from "@mui/material";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";

const Footer = () => {
  const api = useSelector((state) => state.ApiReducer.base_uri);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  const { handleBlur, handleChange, handleSubmit, errors, touched } = useFormik(
    {
      initialValues: {
        email: "",
      },
      validationSchema: yup.object().shape({
        email: yup.string().email("Invalid Email").required("Required"),
      }),
      onSubmit: (values) => {
        setIsLoading(true);
        axios
          .post(`${api}newsletter`, values)
          .then((res) => {
            setIsLoading(false);
            if (res.data.status === 200) {
              setMessage(res.data.message);
              setIsOpen(true);
            } else {
              // setMessage(res.data)
              console.log(res.data);
            }
          })
          .catch((err) => {
            err.response
              ? setMessage(err.response.data.message)
              : setMessage("Something went wrong...");
            setIsOpen(true);
            setIsLoading(false);
            console.log(err);
          });
      },
    }
  );
  const handleClose = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  return (
    <footer className="bg-[#04052E] d-flex justify-content-center px-md-0 px-3">
      <div className="col-md-8 py-4" data-aos="fade">
        <div className="logo d-flex justify-content-center">
          <img src={log2a} />
          <img src={log2b} />
        </div>
        <p className="text-white text-center fw-bold fs-5 py-3">Newsletter</p>
        <div className="row">
          <div className="col-md-9">
            <div className="form-group">
              <label className="form-label text-white">Email Address</label>
              <input
                className="form-control form-control-lg"
                placeholder="Enter email"
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
              />
              <div className="text-danger pt-1">{errors.email}</div>
            </div>
          </div>
          <div className="col-md-3 py-2">
            <div className="form-group mt-4">
              <Button
                disabled={isLoading}
                onClick={handleSubmit}
                className={
                  isLoading
                    ? "px-4 py-2 border border-primary text-webhostels"
                    : "btn-webhostels px-4 py-2"
                }
              >
                {isLoading ? "Subscribing..." : "Subscribe"}
              </Button>
            </div>
          </div>
        </div>
        <div className="py-4">
          <p className="text-newsletter text-center fs-6">
            Subscribe to our newsletter and be among the first to be notified on
            our updates.
          </p>
          {/* <p className="text-newsletter text-center fs-6">
            We will treat your email address exactly how we would want ours
            treated.
          </p> */}
        </div>
        <div className="mb-4 py-2 d-flex justify-content-around flex-md-row flex-column">
          <a href="/" className="text-white pb-2 text-start">
            Home
          </a>
          <a href="/products" className="text-white pb-2 text-start">
            Rent an apartment
          </a>
          <a href="/about" className="text-white pb-2 text-start">
            About Us
          </a>
          <a href="/contact" className="text-white pb-2 text-start">
            Contact
          </a>
        </div>
        <div className="icons d-flex justify-content-center">
          <a href="#">
            <RiFacebookFill
              size={25}
              className="text-[#ffffff] cursor-pointer hover:text-[#454444] duration-500 mx-3 "
            />
          </a>
          <a href="https://x.com/Webhostelsng" target="__blank">
            <BsTwitter
              size={25}
              className="text-[#ffffff] cursor-pointer hover:text-[#454444] duration-500 mx-3"
            />
          </a>
          <a href="https://instagram.com/Webhostelsng" target="__blank">
            <AiFillInstagram
              size={25}
              className="text-[#ffffff] cursor-pointer hover:text-[#454444] duration-500 mx-3 "
            />
          </a>
        </div>
        <p className="text-newsletter text-center pt-4">
          &copy; Webhostelsng 2023 All Rights Reserved
        </p>
      </div>

      {/* Snackbar */}
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={
            message.toLowerCase().includes("successfully") ? "success" : "error"
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </footer>
  );
};

export default Footer;
