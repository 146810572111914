import React from "react";

const Testimonials = () => {
  return (
    <section
      className="py-5 testimonials bg- flex flex-col lg:items-center md:justify-center"
      data-aos="fade"
    >
      <h5 className="fs-2 fw-bold" data-aos="slide-down">
        Testimonials
      </h5>
      <div className=" overflow-y-hidden no-scrollbar overflow-x-scroll lg:overflow-x-hidden   ">
        <div
          data-aos="slide-up"
          className=" flex justify-center items-center  w-fit gap-4 pt-[2.5rem]  "
        >
          <div className=" flex-col items-center justify-center  gap-4  border-[1px] lg:px-[1rem] lg:py-[1.5rem] lg:h-[300px] lg:w-[280px] w-[250px] h-[200px] ">
            <div className=" flex flex-col items-center justify-center gap-3   ">
              <div className=" py-[0.5rem] px-[1rem] lg:px-[1rem]  items-center justify-center bg-colorb  rounded-full ">
                <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                  D
                </div>
              </div>
              <div className="font-sans font-semibold  text-[16px] not-italic">
                David Mark
              </div>
            </div>
            <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  lg:text-[16px]  text-center flex items-center ">
              Having the ticked app on my phone has made me manage my task
              well.I'm always up-to-date on my tasks.
            </div>
          </div>

          <div className=" flex-col  gap-4  border-[1px] lg:px-[1rem] lg:py-[1.5rem] lg:h-[300px] lg:w-[280px] w-[270px] h-[200px] ">
            <div className=" flex  items-center justify-center gap-3 flex-col ">
              <div className=" py-[0.5rem] px-[1rem]  bg-[#FDA758]  rounded-full items-center justify-center">
                <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                  A
                </div>
              </div>
              <div className="font-sans font-semibold  text-[16px] not-italic">
                Adorable Grace
              </div>
            </div>
            <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  lg:text-[16px]  text-center flex items-center ">
              Working with this team has been amazing, i'm happy witjh the
              progress we made building something individuals are happy using.
              Well done team..
            </div>
          </div>

          <div className=" flex-col  gap-4 items-center justify-center border-[1px] lg:px-[1rem] lg:py-[1.5rem] lg:h-[300px] lg:w-[280px] w-[270px] h-[200px] ">
            <div className=" flex  items-center justify-center gap-3 flex-col ">
              <div className=" py-[0.5rem] px-[1rem] bg-[#FDA758] rounded-full items-center justify-center">
                <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                  R
                </div>
              </div>
              <div className="font-sans font-semibold  text-[16px] not-italic">
                Ruth Davidson
              </div>
            </div>
            <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  lg:text-[16px]  text-center flex items-center ">
              This has been the app I would say i needed . I love the interface
              and how easy it is for me to access the features provided. Good
              job guys I will highly recommend.
            </div>
          </div>

          <div className=" flex-col items-center justify-center gap-4  border-[1px] lg:px-[1rem] lg:py-[1.5rem] lg:h-[300px] lg:w-[280px] w-[270px] h-[200px] ">
            <div className=" flex  items-center justify-center gap-3 flex-col ">
              <div className=" py-[0.5rem] px-[1rem]  bg-colorb  rounded-full items-center justify-center">
                <div className=" items-center justify-center font-sans text-[18px] text-[#ffffff]">
                  E
                </div>
              </div>
              <div className="font-sans font-semibold  text-[14px] not-italic">
                Esther George
              </div>
            </div>
            <div className="font-sans  font-semibold text-[#7A7B7E] text-[14px]  lg:text-[16px]  text-center flex items-center ">
              I must commened the efforts use to build this great app. Well done
              guys.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
